import React from 'react';
import '../styles/Loader.css'; // Import your CSS file

const Loader = ({ text }) => {
  return (
      <div className="loader-container">
        <div className="loader">{text}</div>
      </div>
  );
};

export default Loader;
