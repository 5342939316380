import React, { useState, useEffect } from 'react';
import config from '../config/config';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkAuthentication, destroyAuthentication } from '../components/Auth';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import FAQ from '../components/Faq';

import {
  EuiButton,
  EuiCallOut,
  EuiFlyoutBody,
  EuiFlyout,
  EuiText,
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink
} from '@elastic/eui';

function DeviceDetector() {
  // Access the user agent string
  const userAgent = navigator.userAgent;

  // Define regular expressions for common mobile user agents
  const mobileUserAgentRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  // Check if the user agent matches a mobile device
  const isMobileDevice = mobileUserAgentRegex.test(userAgent);

  return isMobileDevice;
}

function Header(){
  const { userData, isLoadingUserData, isMobileDevice, updateUserData, updateIsLoadingUserData, updateIsMobileDevice } = useGlobalContext();
  const [showCallout, setShowCallout] = useState(false); //useState(!Cookies.get('devack')); // Check if cookie is set
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      updateIsLoadingUserData(true);
      updateIsMobileDevice(DeviceDetector());

      try {
        const auth = await checkAuthentication();

        if (auth === 'badToken' || auth === 'noAuth' || auth === 'genericError') {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
          updateUserData(auth);
        }

        setLoading(false);
        updateIsLoadingUserData(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsAuthenticated(false);
        setLoading(false);
        updateIsLoadingUserData(false);
      }
    };

    fetchData(); // Call the async function

  }, []);


  const handleLoginClick = () => {
    // Redirect to the /login route when the link is clicked
    window.location.href = "/login";
  };

  const handleLogoutClick = () => {
    // Redirect to the /login route when the link is clicked
    window.location.href = "/logout";
  };

  const handleAccountClick = () => {
    // Redirect to the /login route when the link is clicked
    window.location.href = "/my";
  };

  const handleAcknowledgement = () => {
    Cookies.set('devack', 'true', { expires: 365 }); // Set the cookie to acknowledge the warning
    setShowCallout(false); // Hide the callout after acknowledgment
  };

  const openFlyout = () => {
    setIsFlyoutVisible(true);
  };
  
  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  let loginLink = "";

  if(isAuthenticated){
    if(location.pathname == "/my"){
      loginLink = (
        <EuiHeaderLink onClick={handleLogoutClick}>Logout</EuiHeaderLink>
      );
    }else{
      loginLink = (
        <EuiHeaderLink onClick={handleAccountClick}>Account</EuiHeaderLink>
      );
    }
  }else{
    loginLink = (
      <EuiHeaderLink onClick={handleLoginClick}>Login</EuiHeaderLink>
    );
  }

    return (
        <>
        <EuiHeader>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLink href="/">
              <img alt="Vanishing Link Gif" style={{height: "32px", marginTop: "3px"}} src='/assets/images/vanishinglink128.gif' />
            </EuiHeaderLink>
            <EuiHeaderLink href="/">
                {config.app.name}
            </EuiHeaderLink>
          </EuiHeaderSectionItem>
    
          <EuiHeaderLinks>
            {/*loading ? ( <>  </> ) : (
              <>
                {loginLink} {userData.Name ? " - " + userData.Name : null}
              </>
            )*/}
            <EuiHeaderLink iconType="securityApp" href={"/security"}>
              Security & Privacy
            </EuiHeaderLink>
            <EuiHeaderLink iconType="help" onClick={openFlyout}>
              FAQ
            </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeader>

        {isFlyoutVisible && (
          <EuiFlyout
            ownFocus
            onClose={closeFlyout}
            aria-labelledby="helpFlyoutTitle"
            size="m"
          >
            <EuiFlyoutBody>
              <EuiText>
                <FAQ />
              </EuiText>
            </EuiFlyoutBody>
          </EuiFlyout>
        )}

        {showCallout && (
        <EuiCallOut title="Development Ahead!" color="warning" iconType="warning">
            <center><p>
            This tool, while live, is still in early development! This is a sharing service not a storage service. Links are Ephemeral and should be used relatively quickly after creation.
            </p>
            <EuiButton size={'xs'} onClick={handleAcknowledgement}>Acknowledge</EuiButton> {/* Acknowledge button */}</center>
        </EuiCallOut>
        )}
        </>
      );
}

export default Header;