import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { checkAuthentication, destroyAuthentication } from '../components/Auth';
import { useNavigate } from 'react-router-dom'

import {
  EuiTitle,
  EuiLink,
  EuiCallOut
} from '@elastic/eui';

function My(){
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        let auth = checkAuthentication();
        if(auth){
            setIsAuthenticated(true);
        }else{
            setIsAuthenticated(false);
        }
        setLoading(false);
    }, [isAuthenticated]); // The empty dependency array ensures this runs only once

    if(!isAuthenticated){
        window.location.href = "/login?redirect=/admin/";
        return;
    }

    return (
        <>
        <Header />
           Hello World
        <Footer />
        </>
      );
}

export default My;