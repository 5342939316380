import BuyMeABeerButton from '../components/Buymeabeer';
import { EuiText, EuiSpacer, EuiLink
} from '@elastic/eui';
export default function FAQ() {

    const faqData = [
      {
        question: 'What is this tool?',
        answer:
          'This tool was originally created to simplify the process of securely sharing passwords or secrets outside of normal communication channels. The generated link, while it may be memorable, is designed to be accessible only once, after which the underlying data is deleted.',
      },
      {
        question: 'Why does this tool exist?',
        answer:
          'This tool exists to address the need for a secure and ephemeral way to transmit sensitive information. It ensures that the shared data is not stored indefinitely, reducing the risk of unauthorized access.',
      },
      {
        question: 'Why did YOU create this tool?',
        answer:
          'I find its easiest to learn a new programming language / method / technique by applying it to a personal project where I can have fun learning',
      },
      {
        question: 'Whats the difference between a Note / Link / File?',
        answer:
          'Keep Reading...',
      },
      {
        question: 'Whats is a note?',
        answer:
          'A Note is simply that. Paste in a secret, piece of code, confession, love letter and it is shared with the recipient just as it was added.',
      },
      {
        question: 'Whats is a link?',
        answer:
          'A link is a private redirect. This allows you to provide someone a link that once opened will automatically redirect to your intended location. Definitely not intended for Rick Rolling',
      },
      {
        question: 'Whats is a file?',
        answer:
          'A file is just that. You can share images, photos, pdfs, pretty much anything with your recipient and when they open the link the file will automatically download.',
      },
      {
        question: 'Where is the security?',
        answer : 'By default links are automatically encrypted browserside. The encryption key is part of the link and is never stored by the server. This means that even though the data is on our servers we cannot see the contents. Once you wish to recover the data navigate to the originally generated link and upon clicking the button to retrieve the data all data related to this key has been removed from our servers. You can turn this feature off under Options though we are not sure why you would want to.'
      },
      {
        question: 'How can I buy you a beer?',
        answer:
          <EuiText><strong>Anything</strong> is appreciated to keep projects like this alive and updated. If you want to buy me a beer, a case, or just help with hosting this tool click the button below! <br></br> <center><BuyMeABeerButton /></center></EuiText>,
      },

      // Add more FAQ items as needed
    ];
  
    return (
      <div>
        <h2>Frequently Asked Questions</h2>
        {/* <ul>
          {faqData.map((faqItem, index) => (
            <li key={index}>
              <strong>Q: {faqItem.question}</strong>
              <p>A: {faqItem.answer}</p>
            </li>
          ))} 
        </ul> */}
        <EuiText>
          <hr />
          <h3>
            What is this tool?
          </h3>
          <h4>
            This tool was originally created to simplify the process of securely sharing passwords or secrets outside of normal communication channels. The generated link, while it may be memorable, is designed to be accessible only once, after which the underlying data is deleted.
          </h4>
          <h3>
            Why does this tool exist?
          </h3>
          <h4>
            This tool exists to address the need for a secure and ephemeral way to transmit sensitive information. It ensures that the shared data is not stored indefinitely, reducing the risk of unauthorized access.
          </h4>
          <h3>
            Whats the difference between a Note / Link / File?
          </h3>
          <h4>
            <ul>
              <li>
                A Note is simply that. Paste in a secret, piece of code, confession, love letter and it is shared with the recipient just as it was added.
              </li>
              <EuiSpacer />
              <li>
                A Sticky is a note that doesn't automatically delete itself when viewed. It will continue to be available until it expires or when someone clicks the Delete button.
              </li>
              <EuiSpacer />
              <li>
                A link is a private redirect. This allows you to provide someone a link that once opened will automatically redirect to your intended location. Definitely not intended for Rick Rolling
              </li>
              <EuiSpacer />
              <li>
                A file is just that. You can share images, photos, pdfs, pretty much anything with your recipient and when they open the link the file will automatically download.
              </li>
            </ul>
          </h4>
          <h3>
            Where is the security?
          </h3>
          <h4>
            By default links are automatically encrypted browserside. The encryption key is part of the link and is never stored by the server. This means that even though the data is on our servers we cannot see the contents. Once you wish to recover the data navigate to the originally generated link and upon clicking the button to retrieve the data all data related to this key has been removed from our servers. You can turn this feature off under Options though we are not sure why you would want to. See more details here: <EuiLink href='/security'>Security and Privacy</EuiLink>
          </h4>
          <h3>
            How can I buy you a beer?
          </h3>
          <h4>
            <strong>Anything</strong> is appreciated to keep projects like this alive and updated. If you want to buy me a beer, a case, or just help with hosting this tool click the button below! <br></br> <center><BuyMeABeerButton /></center>
          </h4>

        </EuiText>
      </div>
    );
  }