import '../styles/Postit.css';
const Postit = ({ data }) => {
    
    return (
        <div class='post-it' contenteditable>
            <h1>Here's your secret:</h1>
            <hr></hr>
            {data}
        </div>
    );
  };

export default Postit;

  