const config = {
    app: {
        name: process.env.APPNAME || "Vanishing Link Generator",
        title: process.env.APPTITLE || "Vanishing.link - Ephemeral Link Generator",
        shortName: process.env.APPSHORTNAME || "Vanishing Link",
        tagline: process.env.APPTAGLINE || "",
        baseDomain: process.env.APPBASEDOMAIN || "vanishing.link",
        version: process.env.version || "v1.5.1"
    },
    restrictions: {
        blockedDomains: [ 'vanishing.link', 'transient.link', 'www.vanishing.link', 'www.transient.link', 'localhost', 'vanilink.com', 'www.vanilink.com', 'vnsh.link', 'www.vnsh.link' ],
        blockedKeys: ["admin","wp-admin","administrator","login","delete","logincallback","docs","help","donate","support","changelog","report","dmca","home","index","http","html","aspx", "regli", "egli", "ron", "ronegli"]
    },
    developer: {
        name: "Ron Egli",
        github: "https://github.com/smugzombie"
    },
    defaultLimits: {
        fileSizeLimit: 10,
        noteCreationTimeout: 5,
        fileUploadTimeout: 60,
        keyLifetime: 24,
        keySizeLimit: 9999
    },
    encryption: {
        iv: "2,235,46,160,125,167,63,53,42,74,131,85"
    }
  };
  
  export default config;