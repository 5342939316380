export const encodedFormData = (inputString) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);
    const base64String = btoa(String.fromCharCode.apply(null, data));
    return base64String;
};
  
export const decodedFormData = (base64String) => {
    const binaryString = atob(base64String);
    const data = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      data[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    const decodedString = decoder.decode(data);
    return decodedString;
};
  
export const generateEncryptionKey = (bits) => {
    const byteArray = new Uint8Array(bits / 8);
    window.crypto.getRandomValues(byteArray);
    return byteArrayToHex(byteArray);
};

export const generateShortEncryptionKey = (bits) => {
  const byteArray = new Uint8Array(bits / 8);
  window.crypto.getRandomValues(byteArray);
  return byteArrayToHex(byteArray).substring(0,6);
};

export const byteArrayToHex = (byteArray) => {
    return Array.from(byteArray, (byte) => {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('');
}

export const decryptData = async (encryptedData, encryptionKey, iv) => {
    //console.log("Decryption Key:", encryptionKey);
    if(encryptionKey.length != 32){
      encryptionKey = encryptionKey.padEnd(32, "0");
    }
    //console.log("Decryption IV:", iv);
    iv = stringToUint8Array(iv);
    try {
      const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(encryptionKey),
        { name: 'AES-GCM' },
        false,
        ['decrypt']
      );
  
      // Convert the base64-encoded encrypted data back to a Uint8Array
      const decodedEncryptedData = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));
  
      // Perform the decryption with the provided IV
      const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        decodedEncryptedData
      );
  
      const textDecoder = new TextDecoder();
      const decryptedText = textDecoder.decode(decryptedData);
  
      return decryptedText;
    } catch (error) {
      console.error('Decryption error:', error);
      throw error;
    }
  };
  
  

export const encryptData = async (data, encryptionKey, iv) => {
    //console.log("Encryption Key:", encryptionKey);
    if(encryptionKey.length != 32){
      encryptionKey = encryptionKey.padEnd(32, "0");
    }

    iv = stringToUint8Array(iv);
    //console.log("Encryption IV:", iv);
    try {
      const textEncoder = new TextEncoder();
      const encodedData = textEncoder.encode(data);
  
      const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(encryptionKey),
        { name: 'AES-GCM' },
        false,
        ['encrypt']
      );
  
      const encryptedData = await window.crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        encodedData
      );
  
      // Convert the encrypted data to a base64-encoded string
      const encryptedBase64 = btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
  
      return encryptedBase64;
    } catch (error) {
      console.error('Encryption error:', error);
      throw error;
    }
  }

export const uint8ArrayToString = (uint8Array) => {
    const textDecoder = new TextDecoder('utf-8');
    return textDecoder.decode(uint8Array);
}

export const stringToUint8Array = (stringData) => {
    const textEncoder = new TextEncoder('utf-8');
    return textEncoder.encode(stringData);
}
  