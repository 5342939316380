import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import config from '../config/config';
import QRCodeGenerator from '../components/QRCode'
import Cookies from 'js-cookie';
import axios from 'axios';
import '../styles/Main.css'; // Import your CSS file
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import { encodedFormData, decodedFormData, generateEncryptionKey, generateShortEncryptionKey, encryptData, decryptData } from '../components/Storage';

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea,
  EuiHorizontalRule,
  EuiButtonGroup,
  EuiToolTip,
  EuiText
} from '@elastic/eui';

function Security() {


  return (
    <>
    <Header />
    <div>
        <EuiSpacer />
        <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem style={{ maxWidth: 800 }}>
                <EuiText>
                    <center><h1>Security</h1></center>
                    <h2>Your secrets stay secret!</h2>
                    <EuiSpacer />
                    <p>
                        All messages undergo rigorous security measures to ensure the utmost protection of your privacy. We employ end-to-end encryption, leveraging the robust AES-256 (Advanced Encryption Standard) algorithm, where a randomly generated hash is used to encrypt your confidential data right on your device. Importantly, the encryption key is never retained; instead, it is securely embedded within the secret link itself. This means that, without access to the complete link, no entity, including us, possesses the capability to decipher your message.
                    </p><p>
                        Furthermore, should you choose to employ a password, it is employed to further fortify the standard encryption. It's important to note that the password is never stored anywhere within our systems. Even in the unlikely event of an attacker gaining access to all of our infrastructure, they would still be unable to access your message. Once a secret has been viewed, we take immediate action by permanently deleting it from our database, with no backups maintained whatsoever. Your data's security and your privacy are our utmost priorities.
                    </p>
                    <EuiSpacer />
                    <p>
                        <h3>Security by design</h3>
                        <ul>
                            <li>All connections are secured via HTTPS</li>
                            <li>All data is stored encrypted (not only secrets)</li>
                            <li>As little third-party code as possible</li>
                            <li>Dependencies are checked and updated regularly</li>
                        </ul>
                    </p>
                    <hr></hr>
                    <EuiSpacer />
                    <EuiSpacer />
                    <center><h1>Privacy</h1></center>
                    <h2>No Tracking or Advertisements!</h2>
                    <EuiSpacer />
                    <p>
                    Your privacy is our top priority, and we're committed to safeguarding it at all costs. Here's how we ensure your data stays private:
                    <EuiSpacer />
                    <ul>
                        <li>No Ads or Tracking: We steer clear of intrusive ads and don't track your online activities. Your online journey is your own.</li>
                        <li>No Data Selling: We never sell your data to third parties. Your information remains with you and us, period.</li>
                        <li>Minimal Personal Data: We only collect the absolute essentials, and we'll only ask for more if there's a good reason.</li>
                        <li>Account Protection: Even if you have an account with us, we won't demand personally identifiable information (PII) unless it's absolutely necessary.</li>
                        <li>Privacy by Design: Our database is structured in a way that every secret you share is completely separate and independent. Even if you use a user account, your secrets can never be traced back to you.</li>
                        <li>Rest assured, your privacy is non-negotiable for us, and we've taken every measure to ensure it remains intact while you enjoy our services.</li>
                    </ul>
                    </p>
                </EuiText>
            </EuiFlexItem>
        </EuiFlexGroup>
    </div>
    <Footer />
    </>
  );
}

export default Security;