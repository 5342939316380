import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { EuiProvider } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_dark.css'
import Home from './pages/Home';
import config from './config/config';
import Data from './pages/Data';
import Login from './pages/login';
import Logout from './pages/logout';
import Admin from './pages/admin';
import Error from './pages/404';
import Security from './pages/security';
import My from './pages/my';
import "./main.css";
import { GlobalProvider } from './components/Global';

function App() {

  useEffect(() => {
    // Get the current port
    const port = window.location.port || '80'; // Default to port 80 if not found

    // Update the page title with the port
    if(port !== '80' && port !== '443'){
      document.title = `[${port}] ${config.app.title}`;
    }else{
      document.title = `${config.app.title}`;
    }
  }, []);

  return (
    <>
    <GlobalProvider>
      <EuiProvider colorMode={'dark'} >
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/login" exact element={<Login/>} />
            <Route path="/logout" exact element={<Logout/>} />
            <Route path="/admin/" exact element={<Admin/>} />
            <Route path="/my" exact element={<My/>} />
            <Route path="/security" exact element={<Security/>} />
            <Route path=":thiskey" element={<Data />} />
          </Routes>    
        </Router>
      </EuiProvider>
    </GlobalProvider>
    </>
  );
}

export default App;
