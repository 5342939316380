import axios from 'axios';
import jwtDecode from 'jwt-decode'; // You can use this library to decode JWTs

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refrshToken');
  const response = await axios.get('/api/auth/refresh', {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (response.status === 200) {
    console.log("Login Response:", response);
    return response.data;
  } else {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    return false;
  }
}

export const checkAuthentication = async () => {
    try {
      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (!accessToken) {
        return 'badToken';
      }

      if (accessToken) {
        // Decode the token
        const decodedToken = jwtDecode(accessToken);
        // Check if the token is expired
        if (decodedToken.exp * 1000 > Date.now()) {
          
        } else {
          localStorage.removeItem('accessToken');
          deleteCookie("authToken");
          return 'badToken';
        }
      }

      if (refreshToken) {
        // Decode the token
        const decodedToken = jwtDecode(refreshToken);
        // Check if the token is expired
        if (decodedToken.exp * 1000 > Date.now()) {
          
        } else {
          let refresh = await refreshToken();
          if(!refresh){
            return 'badToken';
          }
        }
      }
  
      // Send a request to the protected endpoint with the bearer token using Axios
      const response = await axios.get('/api/auth/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        console.log("Login Response:", response);
        return response.data;
      } else {
        window.localStorage.removeItem('accessToken');
        return 'noAuth';
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      return 'genericError';
    }
  };

export const destroyAuthentication = async () => {
    const accessToken = localStorage.getItem('accessToken');
    await fetch('/api/auth/logout', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    window.localStorage.removeItem('accessToken');
}
