import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import {
  EuiTitle,
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiFieldPassword,
  EuiButton,
  EuiProgress,
  EuiCallOut,
} from '@elastic/eui';


export default function Login(props) {
  const { userData, isLoadingUserData, isMobileDevice } = useGlobalContext();

  const { prevPath } = props;

  let path = prevPath;

  if (path === undefined || path === null || path === '') path = '/my';

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [accessGranted, setAccess] = useState(false);

  useEffect(() => {
    if(userData.Username  && !isLoadingUserData){
      window.location.href = "/my";
      return;
    }
  }, [accessGranted]);

  const onLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios({
      method: 'POST',
      url: '/api/auth/login',
      data: {
        Username: username,
        Password: password,
      },
    })
      .then((response) => {
        console.log(response.data.tokens.accessToken)
        if(response.data.tokens.accessToken){
            window.localStorage.setItem('accessToken', response.data.tokens.accessToken);
            window.localStorage.setItem('accessToken', response.data.tokens.accessToken);
            setLoading(false);
            navigate(path);
        }
        setLoading(false);
        setError('Something went wrong. Try again later.')
        
      })
      .catch((error) => {
        setError('Username or password is incorrect. Please try again.');
        if (parseInt(localStorage.getItem("marsdebug")) == 1) {
          console.log(error);
        }
        setLoading(false);
      });
  };

  const errorCallout = () => {
    if (error !== '') {
      return (
        <>
          <EuiCallOut style={{ width: 'auto', maxWidth: '400px', margin: 'auto' }} size='s' color='danger' iconType='alert' title={error} />
          <EuiSpacer size='m' />
        </>
      );
    }
  };

  return (
    <>
      {
        loading ? <EuiProgress size='xs' color='#F46A21' /> : <div style={{ height: 2 }} /> // keeps content from shifting
      }

      <div style={{ alignItems: 'center', textAlign: 'center', paddingTop: 50 }}>
        <form>
          <EuiSpacer size='xxl' />

          <EuiTitle>
            <h1>Links Manager</h1>
          </EuiTitle>

          <EuiSpacer size='xxl' />

          {errorCallout()}

          <EuiPanel style={{ width: 'auto', maxWidth: '400px', height: 232, margin: 'auto' }}>
            <EuiFormRow label='Username' name='username' value={username} onChange={(e) => setUsername(e.target.value)}>
              <EuiFieldText autoFocus />
            </EuiFormRow>

            <EuiFormRow label='Password' name='password' value={password} onChange={(e) => setPassword(e.target.value)}>
              <EuiFieldPassword type='dual' />
            </EuiFormRow>

            <EuiSpacer size='l' />
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton fill={true} onClick={(e) => onLogin(e)} type='submit'>
                  Login
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </form>
      </div>
    </>
  );
}
