import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { checkAuthentication, destroyAuthentication } from '../components/Auth';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import { useNavigate } from 'react-router-dom'

import {
  EuiTitle,
  EuiLink,
  EuiCallOut
} from '@elastic/eui';

function My(){
    const [loading, setLoading] = useState(false);
    const { userData, isLoadingUserData } = useGlobalContext();
    console.log("UserData", userData);

    let navigate = useNavigate();

    if(!userData.Username  && !isLoadingUserData){
        window.location.href = "/login";
        return;
    }

    return (
        <>
            <Header />
                {isLoadingUserData ? (
                    <>
                    Loading
                    </>
                ) : (
                    <>
                    {userData.Username ? (
                        <>
                        Hello {userData.Name},
                        </>
                    ): (
                        <>
                        Uhoh, It looks like you aren't authenticated.
                        </>
                    )}
                    </>
                )}
            <Footer />
        </>
      );
}

export default My;