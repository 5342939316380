import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import QRCodeGenerator from '../components/QRCode'
import { decryptData } from '../components/Storage';
import { useGlobalContext } from '../components/Global';
import Postit from '../components/Postit';

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiTextArea,
  EuiFormRow,
  EuiCallOut,
  EuiLink
} from '@elastic/eui';

function Data() {
  const { isMobileDevice } = useGlobalContext();
  const { thiskey } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [dataType, setDataType] = useState('note');
  const [getData, setGetData] = useState(false);
  const [keyValid, setKeyValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showQR, setShowQR] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const [encryptionKey, setEncryptionKey] = useState();
  const [iv] = useState(config.encryption.iv);

  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;
    // Split the URL by '#' to get the parts before and after the '#' character
    const urlParts = fullUrl.split('#');
    const secretKey = urlParts[1];
    setEncryptionKey(secretKey);
    // Define the URL with the dynamic thiskey parameter
      const apiUrl = `/api/key/${thiskey.toLowerCase()}/check`;
      //console.log("CHECK DATA")
      fetch(apiUrl)
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          setKeyValid(true);
        }else{
          setKeyValid(false);
        }
        return response.json();
      })
      .then(async (responseData) => {
        console.log(responseData)
        setDataType(responseData.type)
        if(responseData.secure){
          if(!window.location.href.split('#')[1]){
            setError("Uhoh, This was an encrypted Note/Link/File and no encryption key was provided. Proceeding will cause the data to be retrieved and the key deleted but the data will be encrypted and potentially useless. Double check the link provided and try again.")
          }
        }
      })
      .catch((error) => {
        setError(error.message); // Store the error message
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteLink = () => {
    fetch(`/api/key/${thiskey.toLowerCase()}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          setError(response.error);
          throw new Error('Failed to store data.');
        }
        return response.json();
      })
      .then((data) => {
        setData({data: "Sticky Deleted Successfully.", data_type: "message"})
      })
      .catch((error) => {
        setData({data: "Sorry, We were unable to Delete the Sticky at this time. Please try again later.", data_type: "message"});
      });
  }

  const restoreLink = () => {
    fetch(`/api/key/${thiskey.toLowerCase()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          setError(response.error);
          throw new Error('Failed to store data.');
        }
        return response.json();
      })
      .then((data) => {
        //console.log('Data stored successfully:', data);
        setData({data: "Link Restored Successfully, Go ahead and reshare the url you are currently visiting.", data_type: "message"})
      })
      .catch((error) => {
        //console.error('Error:', error);
        setData({data: "Sorry, We were unable to restore the link. Please recreate the link manually", data_type: "message"});
      });
  };

  const createNewLink = () => {
    window.location.href = "/";
  }

  const downloadFile = (filePath) => {
    const a = document.createElement('a');
    a.href = filePath;
    a.setAttribute('download', ''); // You can also provide a default file name here
    document.body.appendChild(a); // Append to body to ensure it's in the document
    a.click();
    document.body.removeChild(a); // Clean up the DOM
  };

  const showQRCode = () => {
    setShowQR(true);
  }

  const handleFileDownload = async (file) => {
    if(!downloading){
      if(isMobileDevice){
        setDownloadLink(file);
        window.location.href = file;
        //downloadFile(file);
      }else{
        /*var w = window.open(file, "", "width=600, height=400, scrollbars=no");
        setTimeout(() => { w.close(); }, 30000);*/
        downloadFile(file);
      }
      setDownloading(true);
    }
  }

  const handleShowData = async () => {
    setLoading(true);
    setError("");
    const apiUrl = `/api/key/${thiskey.toLowerCase()}`;
    //console.log("GET DATA")
    // Fetch data from the API
    fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        // Handle 400 and 404 errors
        if (response.status === 400 || response.status === 404) {
          throw new Error('Uhoh, Looks like that link is expired. Links only work for 1 visit or until they expire.');
        } else {
          throw new Error(`Failed to fetch data from ${apiUrl}`);
        }
      }
      return response.json();
    })
    .then(async (responseData) => {
      try{
        responseData.data = await decryptData(responseData.data, encryptionKey, iv);
      }catch(e){
        console.error("No Encryption Found");
      }

      setData(responseData);
    })
    .catch((error) => {
      setError(error.message); // Store the error message
      console.error(error);
    }).finally(() => {
      setLoading(false);
    });;

    setGetData(true);
  }

  // Render different content based on data_type
  const renderContent = () => {
    if (error) {
      return (
        <>
        <EuiSpacer />
        <div>
            <h2>Uhoh! There was an error:</h2>
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTextArea 
                  readOnly 
                  value={error} 
                  rows={20} 
                  width={160} 
                  fullWidth={true}
                />
                { /* <textarea class="euiTextArea" style={{height: '400px', marginLeft: '20px', marginRight: '20px', width: '90% !important'}}>{error}</textarea> */ }
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
          </div>
        </>
      );
    } else if (data) {
      if (data.data_type === 'note') {
        return (
          <>
          <EuiSpacer />
          <div>
          <h2>Here's your secret:</h2>
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTextArea 
                  readOnly 
                  value={data.data} 
                  rows={20} 
                  width={160} 
                  fullWidth={true}
                />
                { /* <textarea class="euiTextArea" style={{height: '400px', marginLeft: '20px', marginRight: '20px', width: '90% !important'}}>{error}</textarea> */ }
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
              <p>Please note the data presented here has already been deleted from our servers and the link is invalidated. <br />Refreshing will cause all loss of data for this key. <br/><br />If you wish to share this information with another user click the button below to restore the link for up to 1 more visit.</p>
            <EuiSpacer />
            <EuiButton type='button' onClick={restoreLink}>
              Restore Link
            </EuiButton>
            <EuiSpacer />
          </div>
        </>
        );
      } else if (data.data_type === 'sticky') {
        return (
          <>
          <EuiSpacer />
          <div>
            <Postit data={data.data}/>
            <EuiSpacer />
              <p>This secret is a Sticky. This means it will continue to work until it is manually removed or it expires. To permanently delete this Sticky click the Delete Button below.</p>
            <EuiSpacer />
            <EuiButton type='button' onClick={deleteLink}>
              Delete Sticky
            </EuiButton>
            <EuiSpacer />
          </div>
        </>
        );
      }else if (data.data_type === 'link') {
        // Automatically redirect to the link or file URL
        
        //window.location.href = data.data;
        if(!redirecting){
          if(isMobileDevice){
            window.location.href = data.data;
          }else{
            window.open(data.data);
          }
          setRedirecting(true);
        }
        return (
          <>
            <EuiSpacer />
            <p>Now redirecting you. Please ensure popups are enabled.</p>
            <EuiSpacer />
          </>
        );
      } else if (data.data_type === 'file'){
        handleFileDownload(data.data);
      } else if (data.data_type === 'message'){
        return (
          <>
            <EuiSpacer />
            <p>{data.data}</p>
            <EuiSpacer />
          </>
        );
      }else {
        return <p>Unsupported data_type: {data.data_type}</p>;
      }
    } else {
      return <p>Fetching data...<EuiSpacer /></p>;
    }
  };

  return (
    <>
      <Header />
        <div style={{ alignItems: 'center', textAlign: 'center', paddingTop: 0 }}>

          {loading ? (
            <>
            <EuiSpacer />
            Validating Link
            
            <EuiSpacer />
            </>
          ):(
            <>
              {!keyValid ? (
                <>
                  <EuiCallOut title="Whoops!" color="danger" iconType="error">
                    <p>
                      Uhoh, Looks like that link is expired. Links only work for 1 visit or until they expire.
                    </p>
                  </EuiCallOut>
                  <EuiSpacer />
                </>
              ):(
                <>
                {getData ? (
                  <>
                    <center>{renderContent()}</center>
                  </>
                ):(
                  <>
                    {error ? (<>
                      <EuiCallOut title="Proceed with caution!" color="warning" iconType="warning">
                        <p>
                          {error}
                        </p>
                      </EuiCallOut>
                    </>) : null}
                    <EuiSpacer />
                    <p>Ephemeral notes are destroyed immediately after viewing. To confirm deletion and retrieve the contents click the Show Note button below.</p>
                    
                    <EuiSpacer />
                    {dataType == "note" ? (
                      <EuiButton type='button' onClick={handleShowData}>
                        Show Note
                     </EuiButton> 
                    ) : null}
                    {dataType == "sticky" ? (
                      <EuiButton type='button' onClick={handleShowData}>
                        Show Sticky
                     </EuiButton> 
                    ) : null}
                    {dataType == "link" ? (
                      <EuiButton type='button' onClick={handleShowData}>
                        Visit Link
                     </EuiButton> 
                    ) : null}
                    {dataType == "file" ? (
                      <EuiButton type='button' onClick={handleShowData}>
                        Download File
                     </EuiButton> 
                    ) : null}

                    {showQR ? null : (
                      <EuiButton type='button' style={{marginLeft: "5px"}} onClick={showQRCode}>
                        QR for Mobile
                      </EuiButton>
                    )}
                    <EuiSpacer />
                    {showQR ? (
                      <EuiFormRow label='' fullWidth>
                        <QRCodeGenerator text={window.location.href} />
                      </EuiFormRow>
                    ) : null}
                  </>
                )}
                
                </>
              )}
            </>
          )}

          {downloading ? (
            <>
              <EuiSpacer />
              Your file is now downloading.  {downloadLink ? ( <> <br />*If download doesn't start - <EuiLink href={downloadLink}>Click here to download</EuiLink> </>) : null }
              <EuiSpacer />
            </>
          ) : null}
          
          <EuiButton type='button' onClick={createNewLink}>
            Create New Ephemeral Link
          </EuiButton>
        </div>
      <Footer />
    </>
  );
}

export default Data;
