import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({ text }) {
    const qrCodeStyle = {
        border: '10px solid white', // Add a 10px white border
        height: '256px',
        width: '256px'
      };

    return (
        <div>
        {/* Render the QR code with the specified text */}
        <QRCode value={text} style={qrCodeStyle} />
        {/* You can add additional content or styles here */}
        </div>
    );
}

export default QRCodeGenerator;
