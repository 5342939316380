import React, { useEffect, useState } from 'react';
import { checkAuthentication, destroyAuthentication } from '../components/Auth';

export default function Logout(props) {
    useEffect(() => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        const asyncFn = async () => { destroyAuthentication(); };
        let auth = asyncFn();
        window.location.href = "/";
    }, []);

  return (
    <>
    </>
  );
}

