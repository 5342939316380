import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import config from '../config/config';
import QRCodeGenerator from '../components/QRCode'
import Cookies from 'js-cookie';
import axios from 'axios';
import '../styles/Main.css'; // Import your CSS file
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import { encodedFormData, decodedFormData, generateEncryptionKey, generateShortEncryptionKey, encryptData, decryptData } from '../components/Storage';

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea,
  EuiHorizontalRule,
  EuiButtonGroup,
  EuiToolTip,
  EuiText
} from '@elastic/eui';

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function isValidURL(url) {
  // Regular expression pattern to match URLs
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(url);
}

function Home() {
  const { userData, isMobileDevice } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [link, setLink] = useState("");
  const [key, setKey] = useState("");
  const [secure, setSecure] = useState(true);
  const [copyText, setCopyText] = useState("Copy");
  const [showIntro, setShowIntro] = useState(!Cookies.get('introack')); // Check if cookie is set
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFileURI, setUploadedFileURI] = useState("");
  const [uploading, setUploading] = useState(false);
  const [optionsShow, setOptionsShow] = useState(false);
  const [customKey, setCustomKey] = useState(false);
  const [encryptionKey, setEncryptionKey] = useState(generateShortEncryptionKey(128));
  const [iv] = useState(config.encryption.iv);
  const fileInputRef = useRef(null);

  const handleIntroAcknowledgement = () => {
    Cookies.set('introack', 'true', { expires: 365 }); // Set the cookie to acknowledge the warning
    setShowIntro(false); // Hide the callout after acknowledgment
  };

  const [formData, setFormData] = useState({
    key: '',
    data_type: 'note', // Default data_type is 'note'
    data: '',
    secure: secure,
  });

  useEffect(() => {
    if(isMobileDevice){
      setCopyText("Share")
    }else{
      setCopyText("Copy")
    }
  }, [isMobileDevice]);

  useEffect(() => {
    if(!formData.key && !customKey){
      generateRandomKey();
    }
    
  }, [formData.key])

  const toggleCustomKey = () => {
    setCustomKey(true);
    setFormData({
      ...formData,
      key: ""
    });
  }

  const generateRandomKey = () => {
    setCustomKey(false);
    let newKey = generateRandomString(10).toLowerCase();
    setKey(newKey)
    setFormData({
      ...formData,
      key: newKey
    });
  }

  const validateKey = (key) => {
    if(config.restrictions.blockedKeys.includes(key)){
      setError(`Key Provided (${key}) for link is restricted / disallowed`)
      return false;
    }
    setError("");
    return true;
  }

  const handleChange = (e) => {
    const { name, value, type, checked = false } = e.target;

    if(name === "key"){
      if(!validateKey(value)){
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSecureChange = () => {
    let newSecure = secure;
    if(secure){
      setSecure(false);
      setEncryptionKey("");
      newSecure = false;
    }else{
      setSecure(true);
      setEncryptionKey(generateShortEncryptionKey(128));
      newSecure = true;
    }
    handleChange({target:{"name": "secure", "value": newSecure}})
  };

  const copyLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Secure One Time Link',
        text: 'Heres your secure One Time Link',
        url: link,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => 
        console.error('Share failed:', error));
        return;
    }

    if (navigator.clipboard) {
      navigator.clipboard.writeText(link)
      setCopyText("Copied");
    } else {
      // The navigator.clipboard component is not available, use alternative method.
      // For example, you can use the document.execCommand('copy') method as a fallback.
      const textToCopy = link;
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
    
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'Text copied to clipboard' : 'Failed to copy text';
        setCopyText(msg);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    
      document.body.removeChild(textArea);
    }    
  }

  const validateRedirect = (data) => {
    let blockedDomains = config.restrictions.blockedDomains;
    const url = new URL(data);
    // Extract the hostname (domain) from the URL
    const userDomain = url.hostname;
    // Check if the user's domain is in the blockedDomains array
    return !blockedDomains.includes(userDomain);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let keySizeLimit = config.defaultLimits.keySizeLimit;

    if(userData.Username){
      try{
        keySizeLimit = userData.Limits.noteSize;
      }catch(e){
        console.error("Note Limit",e)
      }
    }

    if(formData.key.length < 4 || formData.key.length > 40){
      setError("Key length must be between 3 and 40 chars");
      return;
    }

    if(formData.data_type === "link" || formData.data_type === "file"){
      //console.log(formData);
      if(!isValidURL(formData.data)){
        setError("Link provided doesn't appear to be valid");
        return;
      }

      if(!validateRedirect(formData.data)){
        setError("Domain Provided for link is blocked");
        return;
      }
    }

    if(formData.data.length > keySizeLimit){
      setError(`Ephemeral notes are currently limited to ${keySizeLimit} chars`);
      return;
    }
    if(secure){
      formData.data = await encryptData(formData.data, encryptionKey, iv);
    }
      // Send a POST request using Axios
      axios
      .post(`/api/key/${formData.key}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (!response.data) {
          console.error(response);
          setError('Unable to connect to API. Try again later.');
          throw new Error('Failed to store data.');
        }
        if(secure){
          setLink(window.location.href + formData.key + "#" + encryptionKey);
        }else{
          setLink(window.location.href + formData.key);
        }
        
        //console.log('Data stored successfully:', response.data);
        setFormData({
          key: '',
          data_type: 'note',
          data: '',
          secure: secure,
        });
        setError('');
        setCopyText('Copy');
      })
      .catch(async (error) => {
        //formData.data = decodedFormData(formData.data);
        formData.data = await decryptData(formData.data, encryptionKey, iv);
        if (axios.isAxiosError(error)) {
          const response = error.response;
          if (response) {
            //console.log(response);
            if (response.status === 400) {
              if(response.data.error){
                setError(response.data.error)
              }else{
                setError('Bad request. Please check your input data.');
              }
            } else if (response.status === 404) {
              setError('Data not found. Check the key you provided.');
            } else if (response.status === 403) {
              if(response.data.error){
                setError(response.data.error)
              }else{
                setError('Access Forbidden. You do not have permission.');
              }
            } else {
              setError('An error occurred while processing your request.');
            }
          } else {
            setError('Network error. Please try again later.');
          }
        } else {
          setError('Failed to store data.');
        }
        console.error('Error:', error);
      });
  };

  const handleFileUpload = (e) => {
    // Trigger the file input element
    setError();
    if(fileInputRef.current){
      (fileInputRef.current).click();
    }
  };

  const handleNew = () => {
    setLink("");
  }

  const handleShowOptions = () => {
    setOptionsShow(true);
  }

  const handleHideOptions = () => {
    setOptionsShow(false);
  }

  const toggleDataType = (dataType) => {
    handleChange({target: {name : 'data_type', value: dataType}});
  }

  const handleFileSelected = async (e) => {
    const selectedFiles = e.target.files;
    setError("");
    setUploading(true);
    //console.log(selectedFiles, userData);
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const fileName = selectedFile.name;
        const uploadFormData = new FormData();
        const fileSize = parseInt(selectedFile.size);
        let fileSizeLimit = parseInt(config.defaultLimits.fileSizeLimit);
        uploadFormData.append('file', selectedFile);
        let registered = false;

        if(userData.Username){
          registered = true;
          try{
            fileSizeLimit = userData.Limits.fileSize;
          }catch(e){
            console.error("Upload Limit",e)
          }
        }

        if(fileSize > (fileSizeLimit * 1000000)){
          if(registered){
            setError(`Max upload size for your user is ${fileSizeLimit}mb.`);
          }else{
            setError(`Max upload size for non-registered users is ${fileSizeLimit}mb.`);
          }
          setUploading(false);
          return;
        }
  
        try {
          // Send the file to the server
          const response = await axios.post(`/api/upload`, uploadFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
          // Assuming the server responds with a JSON object containing a link
          const s3link = response.data.url;
          if (s3link.includes("vanishing.link")) {
            setFile(fileName);
            setFormData({
              ...formData,
              data: s3link,
            });
            setUploadedFileURI("File Uploaded, Click Create Link to Continue...");
            setUploading(false);
          }
        } catch (error) {
          setUploading(false);
          // Handle Axios errors with status codes
          if (error.response) {
            // The request was made and the server responded with a status code
            console.error('Server responded with status code:', error.response.status);
            console.error('Response data:', error.response.data);
            setError(error.response.data.error);
  
            // Handle specific status codes as needed
            if (error.response.status === 400) {
              setError(error.response.data.message);
            } else if (error.response.status === 403) {
              setError(error.response.data.message);
            }else if (error.response.status === 500) {
              setError("Internal server error");
            } else {
              setError("An error occurred during the upload.");
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
            setError("No response received from the server.");
          } else {
            // Something happened in setting up the request that triggered an error
            console.error('Request error:', error.message);
            setError("Request error: " + error.message);
          }
  
          // Handle any other errors that occur during the upload
          //setUploading(false);
          setFile("Error: " + fileName);
          console.error('Error uploading file:', error);
        }
      }
    }
  };
  
  const toggleButtons = [
    {
      id: `note`,
      label: 'Secret',
    },
    {
      id: `sticky`,
      label: 'Sticky',
    },
    {
      id: `link`,
      label: 'Link',
    },
    {
      id: `file`,
      label: 'File',
    },
  ];

  const keyButtons = [
    {
      id: `custom`,
      label: 'Custom',
      callback: 'toggleCustom'
    },
    {
      id: `random`,
      label: 'Random',
      callback: 'generateRandomKey'
    }
  ];

  return (
    <>
    <Header />
    {showIntro && (
      <>
      <EuiCallOut
      size="s"
      title="Greetings!"
      iconType="help"
      >
        <center>
        <p>
          Vnsh.link lets you share sensitive information online, End-to-end encrypted, One time. Keep confidential information out monitored communication channels and history. A one-time ephemeral link guarantees your secrets can only ever be accessed once - before being destroyed forever.
        </p>
        <EuiButton size="xs" style={{ }} onClick={handleIntroAcknowledgement}>Hide</EuiButton> {/* Acknowledge button */}</center>
      </EuiCallOut>
      </>
    )}
    <div style={{ alignItems: 'center', textAlign: 'center', paddingTop: 5 }}>
    
      <EuiSpacer size='l' />
      <form onSubmit={handleSubmit}>
        <EuiPanel style={{ width: 'auto', maxWidth: '600px', margin: 'auto' }}>

          {link ? (
              <>
                <EuiFormRow label='Sharable Link' fullWidth>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={true}>
                      <EuiFieldText
                      id='link'
                      name='link'
                      value={link}
                      checked='false'
                      readOnly='true'
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton type='button' onClick={copyLink} style={{maxWidth: "150px"}}>
                        {copyText}
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFormRow>
                <EuiFormRow label='QR Code' fullWidth>
                  <QRCodeGenerator text={link} />
                </EuiFormRow>
                <EuiButton type='submit' fill onClick={handleNew}>
                  Create Another Link
                </EuiButton>
                </>
          ) : (
            <>
            {error ? (
            <EuiCallOut title="Whoops! That didn't work.." color="danger" iconType="alert">
              <p>
                {error}
              </p>
            </EuiCallOut>
          ) : null}

          <EuiFormRow label='Data to Store' fullWidth style={{display: 'none'}}>
            <EuiSelect
            id='data_type'
            name='data_type'
            options={[
            { value: 'note', text: 'Note' },
            { value: 'link', text: 'Link' },
            { value: 'file', text: 'File' },
            ]}
            value={formData.data_type}
            onChange={(e) => handleChange(e)}
            checked='false'
            required
            />
          </EuiFormRow>
            
          <EuiText textAlign='left'>
            Share your secrets...
          </EuiText>
          <EuiFormRow label='' fullWidth>
            <>
            <EuiButtonGroup
              legend="Datatype Chooser"
              options={toggleButtons}
              idSelected={formData.data_type}
              onChange={(id) => toggleDataType(id)}
              isFullWidth
            />
            </>
          </EuiFormRow>
          

          {formData.data_type === 'note' || formData.data_type === 'sticky' ? (
            <><EuiTextArea
              fullWidth
              id='data'
              name='data'
              value={formData.data}
              onChange={(e) => handleChange(e)}
              checked='false'
              required
            />
            <EuiFieldText
              fullWidth
              id='dataCounter'
              name='dataCounter'
              value={formData.data.length + `/${userData?.Limits?.noteSize ?? config.defaultLimits.keySizeLimit}`}
              readOnly
              style={{textAlign: "right"}}
            />
            </>
          ) : formData.data_type === 'file' ? (
            // Display file input elements here
            <>
              {uploading ? (
                <Loader text="" />
              ) : null}
              
              <EuiFieldText 
                fullWidth
                id='data'
                name='data'
                onClick={handleFileUpload}
                value={uploadedFileURI}
                checked='false'
                placeholder='Click to Choose File'
                readonly
                required
              ></EuiFieldText>
              <input
                id="uploadHandler"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelected}
              />
              {!userData.Username ? (<>
                <EuiCallOut title="Upload Limit" color="warning" iconType="warning">
                  Non registered user uploads are limited to {config.defaultLimits.fileSizeLimit}MB.
                </EuiCallOut>
              </>) : null }
            </>
          ) : (
            <>

            <EuiFieldText
              fullWidth
              id='data'
              name='data'
              value={formData.data}
              onChange={(e) => handleChange(e)}
              checked='false'
              placeholder='https://'
              required
            />
            </>
          )}

          <EuiFormRow hasChildLabel={false} style={{ display: 'none' }}>
            <EuiCheckbox
            id='secure'
            name='secure'
            label='Secure'
            checked={secure}
            onChange={(e) => handleSecureChange(e)}
            style={{ display: 'none' }}
            />
          </EuiFormRow>

          <EuiSpacer size='l' />

          <div className="button-group" style={{textAlign: "center"}}>
            <EuiButton size='xs' type='submit' fill onClick={handleSubmit}>
              Create Link
            </EuiButton>
            {optionsShow ? (
              <>
              <EuiButton size='xs' type='button' fill onClick={handleHideOptions}>
                Hide Options
              </EuiButton>
              </>
            ) : (
              <>
              <EuiButton size='xs' type='button' fill onClick={handleShowOptions}>
                Options
              </EuiButton>
              </>
            )}
            
          </div>

          {optionsShow ? (
            <>
              <EuiHorizontalRule />
              <EuiFormRow label='URL Key' fullWidth>
              <>
              <EuiFieldText
                fullWidth
                placeholder="Type your custom key"
                value={formData.key}
                prepend=""
                append={
                  <>
                    {customKey ? (
                      <EuiButton size='s' type='button' onClick={generateRandomKey} style={{maxWidth: "150px"}}>
                        Randomize
                      </EuiButton>
                    ) : (
                      <EuiButton size='s' type='button' onClick={toggleCustomKey} style={{maxWidth: "150px"}}>
                        Customize
                      </EuiButton>
                    )}
                  </>
                }
                compressed={true}
                disabled={false}
                readOnly={!customKey}
                onChange={(e) => handleChange(e)}
                name='key'
                id='key'
                aria-label="Use aria labels when no actual label is in use"
              />
              
              </>
            </EuiFormRow>
            <EuiFormRow label='Encryption Key' fullWidth>
              <EuiFieldText
                fullWidth
                placeholder=""
                value={encryptionKey}
                compressed={true}
                disabled={true}
                readOnly={true}
                name='encryptionkey'
                id='encryptionkey'
                aria-label=""
                append={
                  <>
                    {secure ? (
                      <EuiButton size='s' type='button' onClick={handleSecureChange} style={{maxWidth: "150px"}}>
                        Remove Encryption
                      </EuiButton>
                    ) : (
                      <EuiButton size='s' type='button' onClick={handleSecureChange} style={{maxWidth: "150px"}}>
                        Enable Encryption
                      </EuiButton>
                    )}
                  </>
                }
              />
              </EuiFormRow>
            </>
          ) : null}
            </>
          )}

        
        </EuiPanel>
      </form>
    </div>
    <Footer />
    </>
  );
}

export default Home;
